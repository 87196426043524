<route>
{
  "meta": {
    "auth": "statisticsProductRank"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="12">
          <span class="searchText">时间</span>
          <el-date-picker
            value-format="timestamp"
            v-model="searchValue"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker
        ></el-col>

        <el-col :span="6">
          <span class="searchText">上架状态</span>
          <el-select class="searchInput" v-model="publishStatus" placeholder="请选择上架状态">
            <el-option label="全部" :value="''">全部 </el-option>
            <el-option label="没上架" :value="0">没上架 </el-option>
            <el-option label="上架" :value="1">上架</el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input style="display: none"></el-input>
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div class="header" slot="header">
        <span class="title">数据列表</span>

        <el-button size="mini" style="float: right" type="success" @click="downLoadExcel">商品排行导出</el-button>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="index" label="排行"> </el-table-column>
        <el-table-column align="center" prop="name" label="商品名称" width="300"> </el-table-column>
        <el-table-column align="center" prop="money" label="金额">
          <template slot-scope="scope">
            {{ scope.row.money | twoPoint }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="orderCount" label="售出数量"> </el-table-column>
        <el-table-column align="center" prop="distributionMoney" label="分销总佣金">
          <template slot-scope="scope">
            {{ scope.row.distributionMoney | twoPoint }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="diffTime" label="上架时长(天)"> </el-table-column>
        <el-table-column align="center" prop="inventory" label="全收部分剩余库存量"> </el-table-column>
        <el-table-column align="center" prop="visitCount" label="浏览数"> </el-table-column>
        <el-table-column align="center" prop="visitMemberCount" label="浏览人数"> </el-table-column>
        <el-table-column align="center" prop="favCount" label="收藏数"> </el-table-column>
        <el-table-column align="center" label="转化率">
          <template slot-scope="scope"> {{ scope.row.orderRatio }} % </template>
        </el-table-column>
        <el-table-column align="center" prop="saleTimeStart" label="上架时间" width="140">
          <template slot-scope="scope">
            <div>{{ scope.row.saleTimeStart | formatTime }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品售卖结束时间" width="140">
          <template slot-scope="scope"> {{ scope.row.saleTimeEnd | formatTime }} </template>
        </el-table-column>
        <el-table-column align="center" label="商品核销结束时间" width="140">
          <template slot-scope="scope"> {{ scope.row.writeOffTimeEnd | formatTime }} </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :close-on-click-modal="false" title="导出" :visible.sync="download.show" append-to-body width="30%">
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 80px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 80px">上架状态</span>
        <el-select class="searchInput" v-model="download.publishStatus" placeholder="请选择上架状态">
          <el-option label="全部" :value="''">全部 </el-option>
          <el-option label="没上架" :value="0">没上架 </el-option>
          <el-option label="上架" :value="1">上架</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      download: {
        show: false,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/rankProductExport',
        time: [],
        publishStatus: ''
        // switchPublish: 1
      },
      publishStatus: '',
      list: []
    }
  },
  methods: {
    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/rankProductExport',
        time: [],
        publishStatus: ''
        // switchPublish: 1,
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : '',
            publishStatus: this.download.publishStatus
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },

    resetSearch() {
      this.searchValue = []
      this.publishStatus = ''
      this.getList()
    },
    // 请求后端获取商品排行列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      this.$api.statistics
        .statistics('productList', {
          params: {
            startTime: this.searchValue ? this.searchValue[0] : '',
            endTime: this.searchValue ? this.searchValue[1] : '',
            page: this.currentPage,
            size: this.pageSize,
            publishStatus: this.publishStatus
          }
        })
        .then(res => {
          this.list = res.records.map((item, index) => {
            console.log(parseInt(res.current))
            item.index = (parseInt(res.current) - 1) * 10 + (index + 1)
            return item
          })
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less"></style>
